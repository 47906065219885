var ready;

import consumer from '../cable';

ready = function() {
  var CHANNEL;
  CHANNEL = 'AppLatestWorkflowChannel';
  return $('.js-app-latest-workflow').each((index, element) => {
    var $this, code, type;
    code = $(element).data('code');
    type = $(element).data('type');
    $this = $(element);
    return consumer.subscriptions.create({
      channel: CHANNEL,
      code: code,
      type: type
    }, {
      connected: function() {
        return console.log(`Connected! to ${CHANNEL} for ${type} code=${code}`);
      },
      disconnected: function() {
        return console.log(`${type} 'code=${code}' disconnected! 😱`);
      },
      received: function(data) {
        console.log(`${CHANNEL} received data:`, data);
        $this.html(data.html);
        return SNEXT_init_state_labels();
      }
    });
  });
};

$(document).on('turbolinks:load', ready);
