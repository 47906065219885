var ready;

import consumer from './cable';

import {
  Terminal
} from 'xterm';

import {
  FitAddon
} from 'xterm-addon-fit';

ready = function() {
  var $logVolumeCode, $logs_button, $logs_container, $subscription, app_code, app_type, fitAddon, flash_message, on_init_logs, on_load_logs, project_code, term;
  // --- LOGS STREAMS --- #
  $logVolumeCode = $('#logs').data('volume-code');
  $subscription = {
    channel: 'LogStreamChannel',
    code: $logVolumeCode
  };
  flash_message = function(message, type) {
    var current_log;
    current_log = $('#message_center');
    if (type == null) {
      type = 'info';
    }
    if (current_log.length > 0) {
      return current_log.append(`<div class='alert alert-${type}'> ${message} <button type=button class='close' data-dismiss='alert' aria-label='close'> <span aria-hidden>×</span> </button> </div>`);
    }
  };
  if ($logVolumeCode) {
    consumer.subscriptions.create($subscription, {
      connected: function() {
        console.log(`Connected to ${$logVolumeCode}`);
        console.log("Tailing logs");
        $.post({
          url: `/volumes/${$logVolumeCode}/stream_log`,
          dataType: 'json',
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          }
        });
        $('#stop_stream').attr('disabled', false);
        $('#start_stream').attr('disabled', true);
        return flash_message('Logs stream connected!', 'success');
      },
      disconnected: function() {
        // display message to refresh or go back
        // kill tail job
        console.log('Killing stream!');
        $.post({
          url: `/volumes/${$logVolumeCode}/kill_log_stream`,
          dataType: 'json',
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          }
        });
        console.log(`${$logVolumeCode} disconnected! 😱`);
        $('#stop_stream').attr('disabled', true);
        $('#start_stream').attr('disabled', false);
        return flash_message('Logs stream disconnected!', 'warning');
      },
      received: function(data) {
        var current_log;
        if (data.logs) {
          current_log = $('#logs');
          if (current_log.length > 0) {
            current_log.append(data.logs);
          } else {
            current_log.text(data.logs);
          }
          $(document).scrollTop($(document).height());
        }
        if (data.disconnect) {
          console.log('Disconnected!');
          consumer.disconnect();
        }
        if (data.flash) {
          return flash_message(data.flash.message, data.flash.type);
        }
      },
      rejected: function() {
        return console.log('oof');
      }
    });
    $('#start_stream').on('click', function() {
      return consumer.connection.open();
    });
    $('#stop_stream').on('click', function() {
      return consumer.disconnect();
    });
  }
  // --- CONTAINER LOGS --- #
  $logs_container = $('#js-logs-container');
  $logs_button = $('#js-logs-button');
  if ($logs_button.length > 0) {
    app_code = $logs_button.attr('data-app-code');
    app_type = $logs_button.attr('data-app-type');
    project_code = $logs_button.attr('data-project-code');
    term = null;
    fitAddon = null;
    on_load_logs = function() {
      return $.get({
        headers: {
          Accept: "text/plain; charset=utf-8",
          "Content-Type": "text/plain; charset=utf-8"
        },
        url: `/p/projects/${project_code}/${app_type}s/${app_code}/container_logs`,
        success: function(text) {
          return term.write(text);
        }
      });
    };
    on_init_logs = function() {
      $logs_container.removeClass('d-none');
      term = new Terminal();
      term.setOption('theme', {
        background: '#0b0b0a',
        foreground: '#E83E8B '
      });
      term.setOption('convertEol', 'true');
      term.setOption('fontSize', 12);
      fitAddon = new FitAddon();
      term.loadAddon(fitAddon);
      term.open($logs_container[0]);
      on_load_logs();
      fitAddon.fit();
      return $logs_button.on('click', on_load_logs);
    };
    return $logs_button.one('click', on_init_logs);
  }
};

$(window).on('load', ready);

// $(document).on('turbolinks:load', ready)
$(window).on('beforeunload', function() {
  var $logVolumeCode;
  // need this again because of 'ready' scope
  $logVolumeCode = $('#logs').data('volume-code');
  if ($logVolumeCode) {
    return $.post({
      url: `/volumes/${$logVolumeCode}/kill_log_stream`,
      dataType: 'json',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    });
  }
});
