var ready;

import consumer from '../cable';

ready = function() {
  // Create a new subscription for each appId on the page
  return $('.js-updating-state-label').each((index, element) => {
    var $this, channel, objectCode, objectType;
    objectCode = $(element).data('object-code');
    objectType = $(element).data('object-type');
    channel = 'MonsterEnergyDrinkChannel';
    $this = $(element);
    return consumer.subscriptions.create({
      channel: channel,
      code: objectCode,
      type: objectType
    }, {
      connected: function() {
        return console.log(`Connected! to ${channel} for ${objectType} with code ${objectCode}`);
      },
      disconnected: function() {
        return console.log(`${objectCode} 'code=${objectCode}' disconnected! 😱`);
      },
      received: function(data) {
        console.log("Received data:", data);
        switch (data.message_type) {
          case 'application_state_change':
            return this.changeState(data);
        }
      },
      changeState: function(data) {
        console.log("Changing state to:", data.state);
        return this.switchStateClass(data.state, data.label, data.active);
      },
      switchStateClass: function(state, label, active) {
        $this.html(label);
        $this.removeClass();
        $this.addClass(`state-${state} animated`);
        if (active) {
          return $this.prepend($('<div class="spinner-border spinner-border-sm mr-1"></div>'));
        }
      }
    });
  });
};

window.SNEXT_init_state_labels = ready;

$(document).on('turbolinks:load', ready);
