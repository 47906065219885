var ready, servers;

import Rails from '@rails/ujs';

ready = function() {
  return servers();
};

servers = function() {
  var $remote_server_select, $servers_select, load_databases, load_servers;
  $servers_select = $('#js-mssql-server-select');
  $remote_server_select = $('#js-remote-symphony-nexts');
  if ($servers_select.length + $remote_server_select.length !== 1) {
    return;
  }
  if ($servers_select.length > 0) {
    load_databases = function() {
      var $databases_select, id;
      $databases_select = $('#ecourt_app_mssql_database_id');
      id = $servers_select.val();
      if (id === '') {
        $databases_select.html('');
        $databases_select.prop('disabled', 'disabled');
        return;
      }
      return $.get({
        url: `/mssql_servers/${id}/database_options`,
        success: function(html) {
          $databases_select.html(html);
          return $databases_select.prop('disabled', false);
        }
      });
    };
    $servers_select.on('change', load_databases);
  }
  if ($remote_server_select.length > 0) {
    console.log('howdy');
    load_servers = function() {
      var $server_select, code, ecourt_code, project_code;
      project_code = $('#js-project-code').data('project-code');
      ecourt_code = $('#js-project-code').data('ecourt-code');
      $server_select = $('#mssql_server_version');
      code = $remote_server_select.val();
      if (code === '') {
        $server_select.html('');
        $server_select.prop('disabled', 'disabled');
        return;
      }
      return $.get({
        url: `/p/projects/${project_code}/ecourt_apps/${ecourt_code}/remote_symphony_db_servers?symphony_next_code=${code}`,
        success: function(html) {
          $server_select.html(html);
          return $server_select.prop('disabled', false);
        }
      });
    };
    return $remote_server_select.on('change', load_servers);
  }
};

$(document).on('turbolinks:load', ready);
