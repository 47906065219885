(function() {
  var ready;

  ready = function() {
    return $('[data-clipboard-copy]').on('click', function(e) {
      var $button, text, url;
      $button = $(e.target);
      text = $button.data('clipboard-text');
      url = $($button.data('clipboard-target')).val();
      navigator.clipboard.writeText(url);
      $button.tooltip({
        title: 'Copied! 🎉',
        trigger: 'manual',
        placement: 'bottom'
      });
      $button.tooltip('show');
      return setTimeout(function() {
        return $button.tooltip('hide');
      }, 1000);
    });
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
