(function() {
  var ready;

  ready = function() {
    return $('.js-get-loki-service-logs').each(function(index, element) {
      var app_code, app_type, filename_filters, project_code, request, since_timestamp, tz, until_timestamp, url;
      app_code = $(element).data('app-code');
      app_type = $(element).data('app-type');
      project_code = $(element).data('project-code');
      filename_filters = $(element).data('filename-filters');
      since_timestamp = $(element).data('since-timestamp');
      until_timestamp = $(element).data('until-timestamp');
      tz = $(document.body).data('user-timezone');
      url = `/p/projects/${project_code}/${app_type}s/${app_code}/logs`;
      request = {
        url: url,
        headers: {
          'Accept: application/json': 'Accept: application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
          since_timestamp: since_timestamp,
          until_timestamp: until_timestamp
        }
      };
      return $.get(request, function(data) {
        console.log("data:", data);
        return $(data.message.streams).each(function(i, stream) {
          var container, filename, pod, source;
          pod = stream.stream.pod;
          container = stream.stream.container;
          filename = stream.stream.filename;
          if ((filename.indexOf(log_filename_filter)) >= 0) {
            return $(filename_filters).each(function(log_filename_filter) {
              var source;
              source = `${container}: ${log_filename_filter}`;
              return $(stream.values).each(function(j, value) {
                var formattedText, formattedTimestamp, text, timestamp;
                if ((filename.indexOf(log_filename_filter)) >= 0) {
                  timestamp = value[0];
                  text = value[1];
                  formattedTimestamp = new Date(timestamp).toLocaleString(void 0, {
                    timeZone: tz,
                    timeZoneName: 'short'
                  });
                  formattedText = `<br><span class=\"text-muted\">${formattedTimestamp} ${source}</span> ${text}`;
                  return $(element).append(formattedText);
                }
              });
            });
          } else {
            source = `${container}:`;
            return $(stream.values).each(function(j, value) {
              var formattedText, formattedTimestamp, text, timestamp;
              timestamp = value[0];
              text = value[1];
              formattedTimestamp = new Date(timestamp).toLocaleString(void 0, {
                timeZone: tz,
                timeZoneName: 'short'
              });
              formattedText = `<br><span class=\"text-muted\">${formattedTimestamp} ${source}</span> ${text}`;
              return $(element).append(formattedText);
            });
          }
        });
      });
    });
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
