(function() {
  var ready;

  ready = function() {
    var ajax_response_handler, attach_add_users_form_handler;
    ajax_response_handler = function(event) {
      var $project_role, data, status, xhr;
      $project_role = $(event.target).data("project-role");
      [data, status, xhr] = event.detail;
      $(`.js-list-team-users-card-body-${$project_role}`).html(xhr.responseText);
      return attach_add_users_form_handler();
    };
    attach_add_users_form_handler = function() {
      $(".js-add-team-users-form").on("ajax:success", ajax_response_handler);
      return $(".js-add-team-users-form").on("ajax:error", ajax_response_handler);
    };
    return attach_add_users_form_handler();
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
