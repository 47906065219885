(function() {
  var ready;

  ready = function() {
    var $s3_object_upload;
    $s3_object_upload = $('#js-s3-object-upload');
    if ($s3_object_upload.length !== 1) {
      return;
    }
    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    });
    return $('.fileUpload').find('input:file').each(function(i, elem) {
      var barContainer, fileInput, form, progressBar, result_message, secondForm;
      fileInput = $(elem);
      form = $(fileInput.parents('form:first'));
      secondForm = $('form#js-submit-form');
      progressBar = $('<div class=\'progress-bar progress-bar-striped progress-bar-animated\'></div>');
      barContainer = $('<div class=\'progress mb-1 mt-1\' style="display:none;line-height: 1rem"></div>').append(progressBar);
      result_message = $('#result-message-location');
      fileInput.after(barContainer);
      return fileInput.fileupload({
        fileInput: fileInput
      }, {
        url: form.data('url')
      }, {
        type: 'POST'
      }, {
        autoUpload: true
      }, {
        formData: form.data('form-data')
      }, {
        paramName: 'file'
      }, {
        dataType: 'XML'
      }, {
        replaceFileInput: false
      }, {
        progressall: function(e, data) {
          var progress;
          progress = parseInt(data.loaded / data.total * 100, 10);
          progressBar.css('width', progress + '%');
          return progressBar.text('Loading...  ' + progress + '%');
        }
      }, {
        start: function(e) {
          barContainer.css('display', 'block');
          progressBar.css('display', 'block').css('width', '0%').text('Loading...  0%');
          fileInput.css('display', 'none');
          return $('.custom-file-label').css('display', 'none');
        }
      }, {
        done: function(e, data) {
          var key;
          form[0].reset();
          result_message.css('display', 'block');
          result_message.html('🐮 Successfully uploaded! 🤠');
          progressBar.css('background', '#02c24b').text('Uploading done');
          // extract key and generate URL from response
          key = $(data.jqXHR.responseXML).find('Key').text();
          // submit the second form which redirects the user to the next page (it can either be a button or an actual form)
          secondForm.submit();
          return false;
        }
      }, {
        fail: function(e, data) {
          progressBar.css('width', '100%').removeClass('progress-bar-striped progress-bar-animated').addClass('bg-danger').text('Failed');
          barContainer.addClass('progress-bar-danger');
          result_message.css('display', 'block');
          return result_message.html('😥 The file failed to upload. Please inform a developer 😰');
        }
      });
    });
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
