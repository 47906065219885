var ready;

import select2 from 'select2';

select2();

ready = function() {
  $('select.js-select2').select2({
    allowClear: true,
    theme: "bootstrap4"
  });
  return $('select').on('select2:select', function(e) {
    var url;
    url = $(e.params.data.element).attr('href');
    if (url) {
      return Turbolinks.visit(url);
    }
  });
};

$(document).on('turbolinks:load', ready);

$(document).on('turbolinks:before-cache', function() {
  return $('select.js-select2').select2('destroy');
});

$(document).on('ajax:success', ready);

$(document).on('ajax:error', ready);
