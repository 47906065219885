var changes_would, ready;

import Rails from '@rails/ujs';

ready = function() {
  return changes_would();
};

changes_would = function() {
  var form, submit;
  form = $('form[id="edit-app-form"]');
  if (form.length <= 0) {
    form = $('form[id="choose-branch-app-form"]');
  }
  if (form.length <= 0) {
    form = $('form[id="custom_domains_form"]');
  }
  if (form.length <= 0) {
    return;
  }
  submit = function(e) {
    var app_code, app_name, app_type, project_code;
    e.preventDefault();
    project_code = form.data('project-code');
    app_code = form.data('app-code');
    app_name = form.data('app-name');
    app_type = form.data('app-type');
    return Rails.ajax({
      url: `/p/projects/${project_code}/${app_type}_apps/${app_code}/changes_would.json`,
      type: 'PATCH',
      dataType: 'json',
      data: form.serialize(),
      success: function(data) {
        if (data.automatically_deploy) {
          if (confirm(`${app_name} will redeploy after saving. Continue?`)) {
            return form.unbind('submit').submit();
          } else {
            return form.find('input[type="submit"]').prop('disabled', false);
          }
        } else if (data.automatically_deploy_routing) {
          if (confirm(`After saving, ${app_name} will continue running while its routing is updated. Continue?`)) {
            return form.unbind('submit').submit();
          } else {
            return form.find('input[type="submit"]').prop('disabled', false);
          }
        } else {
          return form.unbind('submit').submit();
        }
      },
      error: function(xhr, resp, text) {
        return console.log(xhr, resp, text);
      }
    });
  };
  return form.on('submit', submit);
};

$(document).on('turbolinks:load', ready);
