(function() {
  var ready;

  ready = function() {
    var rangePicker;
    rangePicker = $('[data-behavior=daterangepicker]')[0];
    if (rangePicker) {
      flatpickr(rangePicker, {
        mode: 'range',
        dateFormat: 'm/d/Y',
        defaultDate: [$(rangePicker).val().split(' - ')[0], $(rangePicker).val().split(' - ')[1]],
        onChange: function(selectedDates, dateStr, instance) {
          return $(rangePicker).val(dateStr.replace('to', '-'));
        }
      });
    }
    return $('input[type="text"].flatpickr.date-time').each(function() {
      var formattedOffset, tzAbbr;
      formattedOffset = $(this).data('formattedOffset');
      tzAbbr = $(this).data('tzAbbr');
      return $(this).flatpickr({
        altInput: true,
        altFormat: 'F j Y h:i K ' + tzAbbr,
        enableTime: true,
        dateFormat: 'Y-m-dTH:i:00' + formattedOffset // ISO8601
      });
    });
  };

  $(document).on('turbolinks:load', ready);

  $(document).on('ajax:success', ready);

  $(document).on('ajax:error', ready);

}).call(this);
