// Entry point for the build script in your package.json
import Rails from '@rails/ujs';
import '@rails/activestorage';
import '@rails/actioncable';
import 'trix';
import '@rails/actiontext';
import 'bootstrap';
// jQuery needs to be imported in separate file for dependencies to work
import './jquery';
import 'xterm';
import 'xterm-addon-fit';
import 'chartkick/chart.js';
import 'flatpickr';
import 'jquery-ui';
import 'blueimp-file-upload';
import './main.coffee';
Rails.start();

$(document).on('turbolinks:load', () => {
  $('[data-toggle="popover"]').popover();
  $('.popover-dismiss').popover({
    trigger: 'focus'
  });
});
