var builds, ready;

import Rails from '@rails/ujs';

ready = function() {
  return builds();
};

builds = function() {
  var branches_select, build_number_field, form, load_builds;
  form = $('form[id$="app-form"]');
  if (form.length <= 0) {
    return;
  }
  branches_select = form.find("select[id$='_branch_id']");
  if (branches_select.length !== 1) {
    return;
  }
  build_number_field = form.find("input[id$='_build_number']");
  if (build_number_field.length !== 1) {
    return;
  }
  load_builds = function() {
    var id, selected_value;
    selected_value = build_number_field.val();
    build_number_field.prop('disabled', 'disabled');
    id = branches_select.val();
    if (id === '') {
      return;
    }
    return Rails.ajax({
      url: `/branches/${id}/latest_build`,
      type: 'GET',
      success: function(data) {
        console.log(data);
        build_number_field.val(data.build_number);
        return build_number_field.prop('disabled', false);
      },
      error: function(xhr, resp, text) {
        console.log(xhr, resp, text);
        return build_number_field.prop('disabled', false);
      }
    });
  };
  if (build_number_field.val() === '') {
    load_builds();
  }
  return branches_select.on('change', load_builds);
};

$(document).on('turbolinks:load', ready);
