var ready;

import consumer from '../cable';

import Rails from '@rails/ujs';

ready = function() {
  var CHANNEL;
  CHANNEL = 'EventChannel';
  return $('.js-event-channel-event').each((index, element) => {
    var $this, id, workflow_code;
    id = $(element).data('id');
    workflow_code = $(element).data('workflow-code');
    $this = $(element);
    return consumer.subscriptions.create({
      channel: CHANNEL,
      id: id,
      workflow_code: workflow_code
    }, {
      connected: function() {
        console.log(`Connected! to ${CHANNEL} Event id=${id}`);
        return Rails.ajax({
          url: `/workflows/${workflow_code}/events/${id}.json`,
          type: 'GET',
          dataType: 'json',
          success: function(data) {
            console.log(`${CHANNEL} Event id=${id}: Pulled data`, data);
            if (data.html && $this.data('timestamp') < data.timestamp) {
              $this.data('timestamp', data.timestamp);
              return $this.html(data.html);
            }
          },
          error: function(xhr, resp, text) {
            return console.log(xhr, resp, text);
          }
        });
      },
      disconnected: function() {
        return console.log(`${CHANNEL} Event id=${id}: Disconnected! 😱`);
      },
      received: function(data) {
        console.log(`${CHANNEL} Event id=${id}: Received data`, data);
        if ($this.data('timestamp') < data.timestamp) {
          $this.data('timestamp', data.timestamp);
          return $this.html(data.html);
        }
      }
    });
  });
};

window.initEventChannelHandlers = ready;

$(document).on('turbolinks:load', ready);
