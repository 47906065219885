(function() {
  var ready;

  ready = function() {
    if ($('html').data('bs-theme') === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      $('html').attr('data-bs-theme', 'dark');
    }
    if ($('html').attr('data-bs-theme') === 'dark') {
      $("meta[name='msapplication-TileColor']").attr('content', '#0f0f0f');
      return $("meta[name='theme-color']").attr('content', '#0f0f0f');
    }
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
