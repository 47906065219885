var ready;

import consumer from '../cable';

ready = function() {
  // Create a new subscription for each appId on the page
  return $('.js-updating-overall-state-label').each(function(index, element) {
    var $this, appCode, channel, objectType;
    appCode = $(element).data('app-code');
    objectType = $(element).data('object-type');
    channel = 'ProjectOverviewChannel';
    $this = $(element);
    return consumer.subscriptions.create({
      channel: channel,
      code: appCode,
      type: objectType
    }, {
      connected: function() {
        return console.log(`Connected! to ${channel} for ${objectType} with code ${appCode}`);
      },
      disconnected: function() {
        return console.log(`${appCode} 'code=${appCode}' disconnected! 😱`);
      },
      received: function(data) {
        console.log("Received data:", data);
        switch (data.message_type) {
          case 'state_change':
            console.log("Changing state to:", data.state);
            return this.changeStateClass(data.state, data.label);
        }
      },
      changeStateClass: function(state, label) {
        $this.html(label);
        $this.removeClass();
        $this.parents('tr').removeClass(function(_i, className) {
          return className.match(/project-state-\w*/);
        });
        $this.parents('tr').addClass(`project-state-${state}`);
        $this.addClass('animated');
        $this.parent().removeClass();
        $this.parent().addClass(`app-state-${state}`);
        if (state === 'unstable') {
          return $this.prepend($('<div class="spinner-border spinner-border-sm mr-1"></div>'));
        } else {
          return $this.siblings().remove();
        }
      }
    });
  });
};

window.SNEXT_init_state_labels = ready;

$(document).on('turbolinks:load', ready);
