var ready;

import consumer from '../cable';

ready = function() {
  return $('.js-stream-loki-service-logs').each(function(index, element) {
    var $this, app_code, app_type, channel, project_code;
    channel = "LokiServiceLogStreamChannel";
    app_code = $(element).data('app-code');
    app_type = $(element).data('app-type');
    project_code = $(element).data('project-code');
    $this = $(element);
    return consumer.subscriptions.create({
      channel: channel,
      app_type: app_type,
      app_code: app_code
    }, {
      connected: function() {
        this.pushText(Date.now(), 'ActionCable', "Stream connected.");
        return this.watch();
      },
      disconnected: function() {
        return this.pushText(Date.now(), 'ActionCable', "Stream disconnected.");
      },
      received: function(data) {
        var that;
        switch (data.message_type) {
          case 'error':
            return console.log('Watcher: Error: See data', data);
          case 'expired':
            return this.watch();
          case 'loki_data':
            that = this;
            return $(data.message.streams).each(function(i, stream) {
              var container, filename, filename_filters, pod;
              pod = stream.stream.pod;
              container = stream.stream.container;
              filename = stream.stream.filename;
              filename_filters = $this.data('filename-filters');
              return $(filename_filters).each(function(log_filename_filter) {
                var source;
                if ((filename.indexOf(log_filename_filter)) >= 0) {
                  source = `${container}: ${log_filename_filter}`;
                  return $(stream.values).each(function(j, value) {
                    var text, timestamp;
                    timestamp = value[0];
                    text = value[1];
                    return that.pushText(timestamp, source, text);
                  });
                }
              });
            });
        }
      },
      pushText: function(timestamp, source, text) {
        var css_classes, formattedText, formattedTimestamp, tz;
        css_classes = '';
        switch (source) {
          case 'ActionCable':
            css_classes = 'bg-dark text-white';
        }
        tz = $(document.body).data('user-timezone');
        formattedTimestamp = new Date(timestamp).toLocaleString(void 0, {
          timeZone: tz,
          timeZoneName: 'short'
        });
        formattedText = `<br><span class=\"${css_classes}\"><span class=\"text-muted\">${formattedTimestamp} ${source}</span> ${text}</span>`;
        return $this.append(formattedText);
      },
      watch: function() {
        var url;
        url = `/p/projects/${project_code}/${app_type}s/${app_code}/watch_logs`;
        return $.post({
          url: url,
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
          dataType: 'json'
        });
      }
    });
  });
};

$(document).on('turbolinks:load', ready);
