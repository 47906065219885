var ready, servers;

import Rails from '@rails/ujs';

ready = function() {
  return servers();
};

servers = function() {
  var $servers_select, load_databases;
  $servers_select = $('#js-postgres-server-select');
  if ($servers_select.length !== 1) {
    return;
  }
  load_databases = function() {
    var $databases_select, id;
    $databases_select = $('#rails_app_postgres_database_id');
    id = $servers_select.val();
    if (id === '') {
      $databases_select.html('');
      $databases_select.prop('disabled', 'disabled');
      return;
    }
    return $.get({
      url: `/postgres_servers/${id}/database_options`,
      success: function(html) {
        $databases_select.html(html);
        return $databases_select.prop('disabled', false);
      }
    });
  };
  if ($servers_select.val() === void 0) {
    load_databases();
  }
  return $servers_select.on('change', load_databases);
};

$(document).on('turbolinks:load', ready);
