(function($) {
    $.searchbox = {};

    $.extend(true, $.searchbox, {
        settings: {
            url: '/',
            param: 'q',
            minChars: 0,
            dom_id: '#js-results',
            delay: 100,
            loading: function() {},
            latest_term: ''
        },

        loading: function() {
            $.searchbox.settings.loading()
        },

        resetTimer: function(timer) {
            if (timer) clearTimeout(timer)
        },

        idle: function() {
            //$($.searchbox.settings.loading_css).hide()
        },

        process: function(terms) {
            let path = $.searchbox.settings.url.split('?'),
                query = [$.searchbox.settings.param, '=', terms].join(''),
                base = path[0],
                params = path[1],
                query_string = query;

            if (params) query_string = [params.replace('&amp;', '&'), query].join('&');

            //      $.get([base, '?', query_string].join(''), function(data) { // added ajax=true - rileytg
            $.searchbox.start();
            $.searchbox.settings.latest_term = query;
            $.get([base, '?', query_string + '&ajax=true'].join(''), function(data) {
                if (query === $.searchbox.settings.latest_term) {
                    $($.searchbox.settings.dom_id).html(data);
                    $.searchbox.stop();
                    console.log('Loaded ajax environments request')
                } else {
                    console.log('Ignoring old ajax environments request')
                }
            })
        },

        start: function() {
            $(document).trigger('before.searchbox');
            $.searchbox.loading()
        },

        stop: function() {
            $.searchbox.idle();
            $(document).trigger('after.searchbox')
        }
    });

    $.fn.searchbox = function(config) {
        let settings = $.extend(true, $.searchbox.settings, config || {});

        $(document).trigger('init.searchbox');
        $.searchbox.idle();

        return this.each(function() {
            var $input = $(this);

            $input
                .focus()
                .on('input', (function() {
                    if ($input.val() !== this.previousValue) {
                        $.searchbox.resetTimer(this.timer);

                        this.timer = setTimeout(function() {
                            if (/\S/.test($input.val().trim()) && $input.val().trim().length > $.searchbox.settings.minChars) {
                                $.searchbox.start();
                                $.searchbox.process($input.val())
                            } else {
                                $.searchbox.stop()
                            }
                        }, $.searchbox.settings.delay);

                        this.previousValue = $input.val()
                    }
                })
            )
        })
    }
})(jQuery);
