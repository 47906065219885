var ready;

import consumer from '../cable';

ready = function() {
  var CHANNEL;
  CHANNEL = 'WorkflowChannel';
  $('.js-workflow').each((index, element) => {
    var $this, workflowCode;
    workflowCode = $(element).data('code');
    $this = $(element);
    return consumer.subscriptions.create({
      channel: CHANNEL,
      code: workflowCode
    }, {
      connected: function() {
        return console.log(`Connected! to ${CHANNEL} for workflow with code ${workflowCode}`);
      },
      disconnected: function() {
        return console.log(`${workflowCode} 'code=${workflowCode}' disconnected! 😱`);
      },
      received: function(data) {
        console.log("Received data:", data);
        $this.append(data.event_html);
        SNEXT_init_state_labels();
        return initEventChannelHandlers();
      }
    });
  });
  return $('.js-updating-workflow-label').each((index, element) => {
    var $this, workflowCode;
    workflowCode = $(element).data('object-code');
    $this = $(element);
    return consumer.subscriptions.create({
      channel: CHANNEL,
      code: workflowCode
    }, {
      connected: function() {
        return console.log(`Connected! to ${CHANNEL} for workflow with code ${workflowCode}`);
      },
      disconnected: function() {
        return console.log(`${workflowCode} 'code=${workflowCode}' disconnected! 😱`);
      },
      received: function(data) {
        console.log("Received data:", data);
        $this.html(data.event_message);
        return SNEXT_init_state_labels();
      }
    });
  });
};

$(document).on('turbolinks:load', ready);
