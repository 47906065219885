(function() {
  var ready;

  ready = function() {
    var calendar, calendarEl;
    // fullcalendar js expects a DOM element, not a jQuery object. Using jQuery so it string matches with the "#js-"
    calendarEl = $('#js-calendar')[0];
    if (!calendarEl) {
      return;
    }
    calendar = new FullCalendar.Calendar(calendarEl, {
      initialView: 'dayGridMonth',
      themeSystem: 'bootstrap',
      eventDidMount: function(info) {
        var tooltip;
        return tooltip = new Tooltip(info.el, {
          title: info.event._def.title,
          placement: 'top',
          trigger: 'hover',
          container: 'body'
        });
      },
      headerToolbar: {
        left: 'title',
        right: 'dayGridMonth,timeGridWeek,listWeek prev,next today'
      },
      eventSources: [
        {
          url: 'cal_events.json'
        },
        {
          url: 'go_lives.json'
        }
      ],
      customButtons: {
        addEvent: {
          text: 'Add event',
          bootstrapFontAwesome: 'fa-plus',
          click: function() {
            return window.location = $('#js-new-event-link').attr('href');
          }
        }
      }
    });
    return calendar.render();
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
