(function() {
  var ready;

  ready = function() {
    return $('form.network-filterable').each(function(_, form) {
      return $(form).find('fieldset.network-filterable').each(function(_, fields) {
        var allowed_blocks_cidr_wrapper, previous_value, set_label;
        allowed_blocks_cidr_wrapper = $(fields).find("[data-cidr-blocks='true']");
        previous_value = $(fields).find("input[type='radio']:checked").val();
        set_label = function(value) {
          if (value === 'allowed_networks') {
            return $(allowed_blocks_cidr_wrapper).find('label').text('Allowed Networks');
          } else {
            return $(allowed_blocks_cidr_wrapper).find('label').text('Additional Networks');
          }
        };
        set_label(previous_value);
        return $(fields).find("input[type='radio']").each(function(_, radio) {
          var clicked;
          clicked = function(radio) {
            var new_value, previous_radio;
            new_value = $(radio).val();
            if (new_value === 'any_network') {
              if (confirm("This will allow ANYONE on the web to access your app, and is HIGHLY DISCOURAGED. Are you sure?")) {
                previous_value = new_value;
                allowed_blocks_cidr_wrapper.addClass('d-none');
              } else {
                $(radio).prop('checked', false);
                previous_radio = $(fields).find(`input[type='radio'][value='${previous_value}']`);
                previous_radio.prop('checked', true);
              }
            } else {
              previous_value = new_value;
              allowed_blocks_cidr_wrapper.removeClass('d-none');
            }
            return set_label(previous_value);
          };
          return $(radio).click(function() {
            return clicked(this);
          });
        });
      });
    });
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
