(function() {
  var ready;

  ready = function() {
    var init, input, scrollTo;
    input = $('#js-query');
    if (!(input.length > 0)) {
      return;
    }
    init = function() {
      return window.SNEXT_init_state_labels();
    };
    input.searchbox({
      url: '/search',
      param: 'q',
      dom_id: '#js-results',
      delay: 250
    });
    $(document).on('after.searchbox', function() {
      var query;
      query = input.val();
      return init();
    });
    if ($('#console').length > 0) {
      return;
    }
    scrollTo = function(target) {
      var navbar, rect;
      navbar = $('.navbar')[0];
      rect = target.getBoundingClientRect();
      if (rect.bottom > window.innerHeight) {
        target.scrollIntoView(false);
      }
      if (rect.top < 0) {
        return target.scrollIntoView({
          block: 'end'
        });
      }
    };
    return document.onkeydown = function(event) {
      var $next_result, $selected_result, selected_result;
      $selected_result = $('#js-results .js-result-selected');
      switch (event.keyCode) {
        case 13: // Enter
          selected_result = $selected_result.find('a.js-result-link');
          if (selected_result.length > 0) {
            event.preventDefault();
            return selected_result[0].click();
          }
          break;
        case 38: // ↑
          event.preventDefault();
          if ($selected_result.prev().length) {
            $next_result = $selected_result.prev();
            $next_result.toggleClass('js-result-selected bg-light');
            $selected_result.toggleClass('js-result-selected bg-light');
            return scrollTo($next_result[0]);
          }
          break;
        case 40: // ↓
          event.preventDefault();
          if ($selected_result.next().length) {
            $next_result = $selected_result.next();
            $next_result.toggleClass('js-result-selected bg-light');
            $selected_result.toggleClass('js-result-selected bg-light');
            return scrollTo($next_result[0]);
          }
      }
    };
  };

  window.endModalSearch = ready;

  $(document).on('turbolinks:load', ready);

}).call(this);
