(function() {
  var ready;

  ready = function() {
    $('[data-toggle="tooltip"]').tooltip();
    return $('.custom-file-input').change(function() {
      return $('.custom-file-label').text(this.value);
    });
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
