(function() {
  var bind_remove_events, ready;

  bind_remove_events = function() {
    return $('.js-nested-form-remove').off('click').on('click', function(e) {
      var el;
      e.preventDefault();
      el = $(this).parents('.js-nested-form-removable');
      el.find('input[name$="_destroy]"]').val('true');
      return el.addClass('d-none');
    });
  };

  ready = function() {
    $('.js-nested-form-add').on('click', function(e) {
      var target, template;
      e.preventDefault();
      target = $(this).parents('.nested-form').find('.target');
      template = $(this).parents('.nested-form').find('template').html().replace(/NEW_RECORD/g, new Date().getTime());
      target.append(template);
      return bind_remove_events();
    });
    return bind_remove_events();
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
