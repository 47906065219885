var ready, servers;

import Rails from '@rails/ujs';

ready = function() {
  return servers();
};

servers = function() {
  var $remote_server_select, load_servers;
  $remote_server_select = $('#js-remote-symphony-nexts-portal');
  if (!($remote_server_select.length > 0)) {
    return;
  }
  load_servers = function() {
    var $server_select, code, portal_code, project_code;
    project_code = $('#js-project-code').data('project-code');
    portal_code = $('#js-project-code').data('portal-code');
    $server_select = $('#mysql_server_code');
    code = $remote_server_select.val();
    if (code === '') {
      $server_select.html('');
      $server_select.prop('disabled', 'disabled');
      return;
    }
    return $.get({
      url: `/p/projects/${project_code}/portal_apps/${portal_code}/remote_symphony_db_servers?symphony_next_code=${code}`,
      success: function(html) {
        $server_select.html(html);
        return $server_select.prop('disabled', false);
      }
    });
  };
  return $remote_server_select.on('change', load_servers);
};

$(document).on('turbolinks:load', ready);
