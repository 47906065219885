(function() {
  var ready;

  ready = function() {
    if ($('#build_blocked').is(':checked')) {
      $('#build_block_notice_text_wrapper').show();
    } else {
      $('#build_block_notice_text_wrapper').hide();
    }
    return $('#build_blocked').click(function() {
      if ($(this).is(':checked')) {
        return $('#build_block_notice_text_wrapper').show();
      } else {
        return $('#build_block_notice_text_wrapper').hide();
      }
    });
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
