var ready;

import consumer from '../cable';

ready = function() {
  return $('.js-app-deployment-actions').each((index, element) => {
    var $this, channel, objectCode, objectType, project;
    $this = $(element);
    objectCode = $this.data('object-code');
    objectType = $this.data('object-type');
    project = $this.data('project-code');
    console.log(objectCode);
    console.log(objectType);
    channel = 'MonsterEnergyDrinkChannel';
    return consumer.subscriptions.create({
      channel: channel,
      code: objectCode,
      type: objectType
    }, {
      connected: function() {
        return console.log(`Connected to ${channel} for ${objectType} with code ${objectCode} for changing buttons`);
      },
      disconnected: function() {
        return console.log(`${objectCode} 'code=${objectCode}' disconnected! 😱`);
      },
      received: function(data) {
        console.log("Received data:", data);
        switch (data.message_type) {
          case 'application_state_change':
            return this.changeButtons(data);
        }
      },
      changeButtons: function(data) {
        var $buttons, url_safe_app_type;
        $buttons = $('.js-app-deployment-actions');
        if (!($buttons.length > 0)) {
          return;
        }
        console.log("Changing buttons to support: ", data.state);
        project = $buttons.data('project-code') || null;
        switch (objectType) {
          case 'EcourtApp':
            url_safe_app_type = 'ecourt_apps';
            break;
          case 'JdaApp':
            url_safe_app_type = 'jda_apps';
            break;
          case 'PortalApp':
            url_safe_app_type = 'portal_apps';
            break;
          case 'PrecomposedApp':
            url_safe_app_type = 'precomposed_apps';
            break;
          case 'RailsApp':
            url_safe_app_type = 'rails_apps';
        }
        return $.get({
          url: `/p/projects/${project}/${url_safe_app_type}/${objectCode}/deployment_actions`,
          success: function(html) {
            return $buttons.html(html);
          }
        });
      }
    });
  });
};

window.SNEXT_init_state_labels = ready;

$(document).on('turbolinks:load', ready);
