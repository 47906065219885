(function() {
  var ready;

  ready = function() {
    $('.js-modal-show-automatically:last').modal('show');
    $('.js-modal-show-automatically:last').on('hidden.bs.modal', function() {
      var params, url;
      url = new URL(window.location);
      params = new URLSearchParams(url.search);
      params.delete('wf');
      return window.history.replaceState({}, '', url.origin + url.pathname + params.toString());
    });
    return $('.modal').on('shown.bs.modal', function() {
      var input;
      input = $(this).find('*').filter(':input:visible:first');
      return input.trigger('focus');
    });
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
