(function() {
  var ready;

  ready = function() {
    return $.get({
      url: '/jobs/mini_dashboard',
      success: function(html) {
        return $("#jobList").html(html);
      },
      error: function() {
        return $("#jobList").html('<div class="px-3 text-danger">We\'re sorry, something went wrong...</div>');
      }
    });
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
