(function() {
  var ready;

  ready = function() {
    if (!($('js-lazy-load-canary-events').length > 0)) {
      return;
    }
    return $.get({
      url: "/switchboard/canary_events_api",
      success: function(html) {
        return $('js-lazy-load-canary-events').html(html);
      },
      error: function(error) {
        return $('js-lazy-load-canary-events').html('<div class = "text-danger">ERROR. Check console.</div>');
      }
    });
  };

  $(document).on('turbolinks:load', ready);

}).call(this);
